import { Link } from "gatsby"
import React from "react"
import { css } from "@emotion/core"
import { v4 as uuidv4 } from 'uuid'

const mobileMenu = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #4C4141;
  z-index: 800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 759px) {
    display: none;
  }
`
const navLink = css`
  display: block;
  text-decoration: none;
  color: currentColor;
  line-height: 1;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`

const MobileMenu = ({ data, handleNavClick }) => {
  const currentPath = typeof window !== "undefined" && window.location.pathname

  return (
    <nav
      css={css`
        ${mobileMenu};
      `}
    >
        {data.allData.nodes.map(node => {
          if (typeof currentPath.includes === "function") {
            return (
              !currentPath.includes(node.uri) && (
                <Link
                css={navLink}
                key={uuidv4()}
                to={`/${node.uri}`}
              >
                {node.title}
              </Link>
              )
            )
          }
          return null
        })}
    </nav>
  )
}

export default MobileMenu
