import React from "react"
import { css } from "@emotion/core"

const cursor = css`
  position: fixed;
  pointer-events: none;
  cursor: none;
  top: 50%;
  left: 50%;
  @media (hover: none) and (pointer: coarse) {
    display: none;
}
`

class Cursor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {cursorText: ""}
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseMove(e) {
    const windowWidth = window.innerWidth
    if (e.pageX < windowWidth / 2) {
      if (this.state.cursorText !== "Previous") {
        this.setState({
          cursorText: "Previous"
        })
      }
    } else {
      if (this.state.cursorText !== "Next") {
        this.setState({
          cursorText: "Next"
        })
      }
    }
  }

  componentDidMount() {
    this.setState({cursorText: ""})

    if (typeof window !== undefined) {
      window.addEventListener('mousemove', this.handleMouseMove)
    }
  }

  componentWillUnmount() {
    if (typeof window !== undefined) {
      window.removeEventListener('mousemove', this.handleMouseMove);
    }
  }

  render() {
    const isTouch = 'ontouchstart' in document.documentElement;
    const {cursorText} = this.state;
    return(
      <div className="cursor" css={css`${cursor};${isTouch && 'display: none;'}`}>{cursorText}</div>
    )
  }
}

export default Cursor
