import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
`

const GalleryContainer = styled.div`
  width: 100vw;
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1060px) {
    transform: scale(1.5);
  }
  @media screen and (max-width: 760px) {
    transform: scale(2);
  }
`

const LeftImage = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  width: 40vw;
  max-width: 600px;
  max-height: 80vh;
  max-height: calc(var(--vh, 1vh) * 80);
  @media screen and (max-width: 960px) {
    width: 47.5vw;
    max-height: 47.5vh;
    max-height: calc(var(--vh, 1vh) * 47.5);
  }
`

const RightImage = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  width: 40vw;
  max-width: 600px;
  max-height: 80vh;
  max-height: calc(var(--vh, 1vh) * 80);
  @media screen and (max-width: 960px) {
    width: 47.5vw;
    max-height: 47.5vh;
    max-height: calc(var(--vh, 1vh) * 47.5);
  }
`

function HomepageContent({
  currentPage,
  initialChance,
  handleVideoClick,
  images,
  videos,
  selectedVideo,
  leftImageCount,
  rightImageCount,
  handleGalleryClick,
  handleGalleryMouseMove,
}) {
  const isSSR = typeof window === "undefined"
  const leftImages = images.map((i, index) => {
    return (
      <Img
        key={index}
        loading="eager"
        backgroundColor="#4C4141"
        css={css`
          flex: 1;
          ${leftImageCount === index ? "display: block;" : "display: none;"}
          pointer-events: none;
          position: relative;
          right: -50%;
        `}
        fluid={i.image.local.childImageSharp.fluid}
      />
    )
  })
  const rightImages = images.map((i, index) => {
    return (
      <Img
        key={index}
        loading="eager"
        backgroundColor="#4C4141"
        css={css`
          flex: 1;
          ${rightImageCount === index ? "display: block;" : "display: none;"}
          pointer-events: none;
          position: relative;
          left: -50%;
        `}
        fluid={i.image.local.childImageSharp.fluid}
      />
    )
  })
  return (
    <Container className="hideUntilLoaded" onMouseMove={handleGalleryMouseMove}>
      {initialChance < 0.25 && (
        <video
          className="auto-video"
          onClick={() => {
            handleVideoClick()
          }}
          css={css`
            max-width: 80vw;
            max-height: 80vh;
            cursor: pointer;
            @media screen and (max-width: 760px) {
              max-width: 95vw;
              max-height: 95vh;
            }
          `}
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
        >
          <source src={selectedVideo} type="video/mp4" />
        </video>
      )}
      <GalleryContainer
        css={css`
          ${currentPage === null && "cursor: none;"}
          ${initialChance < 0.25 && "display: none;"}
        `}
        onClick={handleGalleryClick}
        id="gallery-container"
      >
        <LeftImage id="gallery-left">{leftImages}</LeftImage>
        <RightImage id="gallery-right">{rightImages}</RightImage>
      </GalleryContainer>
      {!isSSR && (
        <React.Suspense fallback={null}>
          <div hidden={true}>
            <video
              className="auto-video"
              onClick={() => {
                handleVideoClick()
              }}
              css={css`
                max-width: 80vw;
                max-height: 80vh;
                cursor: pointer;
                @media screen and (max-width: 760px) {
                  max-width: 95vw;
                  max-height: 95vh;
                }
              `}
              playsInline={true}
              autoPlay={true}
              muted={true}
              loop={true}
            >
              <source src={selectedVideo} type="video/mp4" />
            </video>
          </div>
        </React.Suspense>
      )}
    </Container>
  )
}

export default HomepageContent
