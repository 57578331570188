import { Link } from "gatsby"
import React from "react"
import Obfuscate from "react-obfuscate"
import { css } from "@emotion/core"
import Clock from "./Clock"
import { v4 as uuidv4 } from 'uuid'

const navLink = css`
  display: inline-block;
  text-decoration: none;
  color: currentColor;
  line-height: 1;
  & :not(:first-of-type) {
    margin-left: 2rem;
  }
`
const contactLink = css`
  position: fixed;
  bottom: 1.2rem;
  right: 1.4rem;
`
const header = css`
  display: grid;
  width: 100%;
  height: auto;
  position: fixed;
  grid-template-columns: 14rem auto auto;
  grid-template-areas: "title currentPage nav";
  padding: 1rem 1.4rem;
  z-index: 2000;
  @media screen and (max-width: 520px) {
    grid-template-columns: 8rem auto auto;
  } 
`

const Header = ({ data, currentPage, handleMenuClick, showMenu, handleNavClick }) => {
  const currentPath = typeof window !== "undefined" && window.location.pathname

  return (
    <header className="hideUntilLoaded" css={header}>
      <div
        css={css`
          grid-area: title;
          z-index: 500;
        `}
      >
        <Link
          css={navLink}
          to={`/`}
          onClick={handleNavClick}
        >
          <h1>Olivia Punnett</h1>
        </Link>
      </div>
      <div
        css={css`
          grid-area: currentPage;
          z-index: 500;
        `}
      >
        {currentPage && <h1 css={css`
          @media screen and (max-width: 760px) {
            display: none;
          }
        `}>{currentPage}</h1>}
      </div>
      <nav
        css={css`
          grid-area: nav;
          text-align: right;
          margin-left: 2rem;
          z-index: 500;
          @media screen and (max-width: 760px) {
            display: none;
          }
        `}
      >
        {data.allData.nodes.map(node => {
          if (typeof currentPath.includes === "function") {
            return (
              !currentPath.includes(node.uri) && (
                <Link css={navLink} key={uuidv4()} onClick={handleNavClick} to={`/${node.uri}`}>
                  {node.title}
                </Link>
              )
            )
          }
          return null
        })}
      </nav>
      <button css={css`
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 20px;
        color: currentColor;
        text-align:right; cursor: pointer;
        @media screen and (min-width: 759px) {
          display: none;
        }
      `} onClick={handleMenuClick}>{showMenu ? 'Close' : 'Menu'}</button>
      <Obfuscate
        css={css`
          ${navLink};
          ${contactLink}
        `}
        email={data.global.email}
        linkText="Contact"
        headers={{
          subject: 'Hi Livvy'
        }}
      >Contact</Obfuscate>
      <Clock />
    </header>
  )
}

export default Header
