import { useStaticQuery, graphql } from "gatsby"

export const useHomepageContent = () => {
  const { allHomepage } = useStaticQuery(
    graphql`
      query HomePageContent {
        allHomepage {
          nodes {
            videos {
              url
            }
            image {
              image {
                local {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 85) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                        presentationHeight
                        presentationWidth
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allHomepage.nodes[0]
}