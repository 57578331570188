import React, { useState, useEffect } from "react"
import clockWorker from "./ClockWorker";
import { css } from "@emotion/core"
import "../css/clock.css"

const Clock = () => {
  const deg = 6;
  const [day, setDay] = useState(new Date());

  let hh = day.getHours() * 30;
  let mm = day.getMinutes() * deg;
  let ss = day.getSeconds() * deg;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      clockWorker.time().then(newTime => setDay(newTime))
    }
    return () => { isMounted = false };
  }, [day])

  return (
    <>
      <div className="clock" css={css`position: fixed; bottom: 1rem; left: 1rem;`}>
        <div className="hour">
          <div className="hr" css={css`transform: rotateZ(${(hh)+(mm/12)}deg)`} id="hr"></div>
        </div>
        <div className="min">
          <div className="mn" css={css`transform: rotateZ(${mm}deg)`}  id="mn"></div>
        </div>
        <div className="sec">
          <div className="sc" css={css`transform: rotateZ(${ss}deg);`} id="sc"></div>
        </div>
      </div>
    </>
  )
}

export default Clock