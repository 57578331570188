import React, { useState, useEffect } from "react"
import { Global, css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import { useHomepageContent } from "../hooks/use-homepage-content"
import { shuffleArray } from "../helpers"
import Header from "./Header"
import MobileMenu from "./MobileMenu"
import Cursor from "./Cursor"
import HomepageContent from "./HomepageContent"
import Clouds from "../videos/clouds.mp4"
import Clothes from "../videos/clothes.mp4"
import Water from "../videos/water.mp4"
import Dust from "../videos/dust.mp4"
import Window from "../videos/window.mp4"
import "../css/reset.css"

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    {
      allData {
        nodes {
          title
          uri
        }
      }
      global {
        email
      }
    }
  `)

  const { image } = useHomepageContent()
  const [images, setImages] = useState(Array.from(image))
  const [initialChance, setInitialChance] = useState(0.5)
  const [videos, setVideos] = useState([Clouds, Clothes, Water, Dust, Window])
  const [selectedVideo, setSelectedVideo] = useState(videos[Math.floor(Math.random() * videos.length)]);
  const [leftImageCount, setLeftImageCount] = useState(0)
  const [rightImageCount, setRightImageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(null)
  const [cursorVisible, setCursorVisible] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [initialCheck, setInitialCheck] = useState(false);

  useEffect(() => {
    if (initialCheck === false) {
      setInitialCheck(true);
      if (images.length >= 10) {
        setImages(shuffleArray(images).splice(0, 10))
      } else {
        setImages(shuffleArray(images))
      }
    } else {
      setImages(shuffleArray(images))
    }
  }, [])

  // useEffect(() => {
  //   return globalHistory.listen(({ action }) => {
  //     if (action === 'PUSH') setShowMenu(false)
  //   })
  // }, [setShowMenu])

  const childrenWithProps = React.Children.map(children, (child, i) => {
    return React.cloneElement(child, {
      setCurrentPage,
      hasLoaded,
      cursorVisible,
      setCursorVisible,
      setHasLoaded,
      setShowMenu
    })
  })

  function handleMenuClick() {
    setShowMenu(!showMenu);
  }

  function handleNavClick() {
      setShowMenu(false);
      setInitialChance(Math.random());
      setImages(shuffleArray(images));
      setLeftImageCount(2);
      setRightImageCount(2);
  }

  function handleVideoClick() {
    setInitialChance(0.5);
    console.log('oh hey');
  }

  function handleGalleryMouseMove(e) {
    const cursor = document.querySelector(".cursor")
    if (cursor) {
      cursor.style.left = `${e.pageX}px`
      cursor.style.top = `${e.pageY}px`
    }
    if (
      e.target.id === "gallery-container" ||
      e.target.id === "gallery-left" ||
      e.target.id === "gallery-right"
    ) {
      if (cursorVisible === false) {
        setCursorVisible(true)
      }
    } else {
      if (cursorVisible === true) {
        setCursorVisible(false)
      }
    }
  }

  function handleGalleryClick(e) {
    if (typeof window !== undefined) {
      const windowWidth = window.innerWidth
      if (e.pageX < windowWidth / 2) {
        setLeftImageCount(
          leftImageCount - 1 < 0 ? images.length - 1 : leftImageCount - 1
        )
      } else {
        setRightImageCount(
          rightImageCount + 1 > images.length - 1 ? 0 : rightImageCount + 1
        )
      }
    }
  }

  return (
    <>
      <Global
        styles={css`
          ::selection {
            color: white;
            background: #debfb8;
          }
          body {
            font-family: neue-haas-unica, sans-serif;
            color: white;
            position: relative;
          }
        `}
      />
      <Header
        data={data}
        currentPage={currentPage}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        handleNavClick={handleNavClick}
        handleMenuClick={handleMenuClick}
      />
      {showMenu && <MobileMenu data={data} />}
      <HomepageContent
        currentPage={currentPage}
        initialChance={initialChance}
        images={images}
        videos={videos}
        selectedVideo={selectedVideo}
        leftImageCount={leftImageCount}
        rightImageCount={rightImageCount}
        handleGalleryClick={handleGalleryClick}
        handleGalleryMouseMove={handleGalleryMouseMove}
        handleVideoClick={handleVideoClick}
      />
      {cursorVisible && (
        <Cursor />
      )}
      {childrenWithProps}
    </>
  )
}
