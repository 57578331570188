module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Olivia Punnett","short_name":"Olivia Punnett","description":"Livvy Punnett is an artist with a curatorial practice, lecturing in Fine Art at Nottingham Trent.","icon":"src/images/icon.png","start_url":"/","background_color":"#DEBFB8","theme_color":"#f9f5f2","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"055251f58508ea3e668e5f51d58daf27"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
